import React from 'react';
import '../Main/css/Footer.css';

const FooterPage = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <footer>
          <div className="footer-content">
            <div className="footer-column">
              <h3>Follow Us</h3>
              <div className="social-icons">
                <a href="#facebook"><i className="fab fa-facebook-f"></i></a>
                <a href="#twitter"><i className="fab fa-twitter"></i></a>
                <a href="#instagram"><i className="fab fa-instagram"></i></a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>&copy; 2024 2Swift Productions. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default FooterPage;