import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LandingPage from './Main/LandingPage';
import Navbar from '../src/Nav/Navbar';
import HomePage from './Main/HomePage';
import ServicesPage from './Pages/ServicesPage';
import ContactPage from './Pages/ContactPage';
import AboutMe from './Main/AboutMe';
import Documentary from './Main/Documentary';
import ProjectPage from './Main/ProjectPage';
import Services from './Main/MyServices';
import ArtistsSection from './Main/Clients';
import ClientsPage from './Pages/ClientsPage';
import AboutPage from './Pages/AboutUsPage';
import FooterPage from './Main/Footer';


function App() {
  const hasVisitedLandingPage = localStorage.getItem('hasVisitedLandingPage');

  return (
    <Router>
      <MainContent hasVisitedLandingPage={hasVisitedLandingPage} />
    </Router>
  );
}

const MainContent = ({ hasVisitedLandingPage }) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/welcome';

  return (
    <>
      {!isLandingPage && <Navbar />} {/* Conditionally render Navbar */}
      <Routes>
        {!hasVisitedLandingPage && <Route path="/" element={<Navigate to="/welcome" />} />}
        <Route path="/welcome" element={<LandingPage />} />
        <Route path="/home" element={
          <>
            <HomePage />
            <AboutMe />
            <Services />
            <ArtistsSection />
            <ProjectPage />
            <Documentary />
            <FooterPage />
          </>
        } />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/clients" element={<ClientsPage />} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </>
  );
};

export default App;
