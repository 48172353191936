import React from 'react';
import Contact from '../Main/Contact';
import Footer from '../Main/Footer';

const ContactPage = () => {
  return (
    <div>
      <Contact />
      <Footer />
    </div>
  );
};
  
export default ContactPage ;