import React, { useState } from 'react';
import '../Main/css/Contact.css';

function ContactPage() {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    const formData = new FormData(event.target);

    formData.append("access_key", "YOUR_ACCESS_KEY_HERE");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      setResult("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="contact-container">
      {/* Left Side: Contact Info */}
      <div className="contact-left">
      <h3>Reach Out to Us</h3>
      <h1>Let’s Collaborate</h1>
      <p>Have a question or need to discuss your next project? We're here to help and would love to hear from you.</p>
      <div className="contact-info">
        <p><i className="fas fa-map-marker-alt"></i> Location <br /> Los Angeles, CA</p>
      </div>
      </div>

      {/* Right Side: Contact Form */}
      <div className="contact-right">
      <h3>Get in Touch</h3>
        <form>
          <div className="input-group">
            <div className="input-field">
              <label>Name</label>
              <input type="text" placeholder="Enter your name" />
            </div>
            <div className="input-field">
              <label>Email</label>
              <input type="email" placeholder="Enter your email" />
            </div>
          </div>
          <div className="input-group">
            <div className="input-field">
              <label>Number</label>
              <input type="tel" placeholder="Enter your phone number" />
            </div>
            <div className="input-field">
              <label>Address</label>
              <input type="text" placeholder="Enter your address" />
            </div>
          </div>
          <div className="input-field">
            <label>Message</label>
            <textarea placeholder="Type your message..." />
          </div>
          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactPage;