import React, { useEffect } from 'react';
import swiftImg1 from '../assets/swift-img1.jpeg';
import swiftImg2 from '../assets/swift-img2.jpg';
import '../Main/css/AboutUs.css';

const AboutUs = () => {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
    
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('revealed');
        }
      });
    });

    const missionSection = document.querySelector('.mission-section');
    const visionSection = document.querySelector('.vision-section');

    observer.observe(missionSection);
    observer.observe(visionSection);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="aboutus-container">
      {/* Our Mission Section */}
      <section className="mission-section">
        <div className="mission-content">
          <h2>Our Mission</h2>
          <p>
            At 2SWIFT Productions, our mission is to craft unforgettable sonic experiences that resonate deeply with listeners and inspire artistic evolution. We are committed to pushing the boundaries of musical production while preserving the authenticity and essence of every artist we collaborate with.
          </p>
          <blockquote>
            "Our mission is to blend tradition with innovation, creating soundscapes that both honor the roots of music and push it toward the future. Every beat, every melody, is designed to captivate, connect, and inspire."
          </blockquote>
          <div className="mission-person">
            <img
              src={swiftImg1}
              alt="Swift"
              className="mission-avatar"
            />
            <div className="person-details">
              <h4>Juan "Swift" Zavala</h4>
              <p>CEO, 2SWIFT Productions</p>
            </div>
          </div>
        </div>
        <div className="mission-image">
          <img
            src={swiftImg1}
            alt="Swift"
            className="image-rounded"
          />
        </div>
      </section>

      {/* Our Vision Section */}
      <section className="vision-section">
        <div className="vision-image">
          <img
            src={swiftImg2}
            alt="Vision"
            className="image-rounded"
          />
        </div>
        <div className="vision-content">
          <h2>Our Vision</h2>
          <p>
            Our vision is to be the driving force behind musical evolution, pushing creative boundaries while staying rooted in tradition. We aim to redefine the auditory experience by seamlessly blending diverse genres and styles, from traditional cumbia to modern rap, creating dynamic soundscapes that captivate audiences around the globe.
          </p>
          <ul className="checkmark-list">
            <li><div className="circle-check"><i className="fas fa-check"></i></div> Foster innovation by pushing the boundaries of music production.</li>
            <li><div className="circle-check"><i className="fas fa-check"></i></div> Elevate artists and creators, helping them bring their musical vision to life.</li>
            <li><div className="circle-check"><i className="fas fa-check"></i></div> Connect traditional sounds with modern creativity to resonate with diverse audiences.</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;