import React, { useEffect } from 'react';
import ClientCard from '../Main/ClientCard';
import '../Main/css/ClientsList.css';

const artists = [
    { name: 'C-Kan', image: 'https://www.billboard.com/wp-content/uploads/2020/09/c-kan-2020-billboard-1548-1601306801.jpg?w=942&h=623&crop=1' },
    { name: 'Melodico', image: 'https://viberate-upload.ams3.cdn.digitaloceanspaces.com/prod/entity/artist/melodico-m2O9q' },
    { name: 'Don Aero', image: 'https://popnable.com/images/singers/temp/don_aero_mexico_top_50_157.jpg' },
    { name: 'Dalia Haro', image: 'https://www.rosadistrito.com/wp-content/uploads/2021/09/daliaharo.jpg' },
    { name: 'Crooked Stilo', image: 'https://geo-media.beatsource.com/image_size/500x500/7/1/1/711e842f-ebda-44f9-8397-64c23f82bfde.jpg' },
    { name: 'Damy Cortes', image: 'https://scontent.ffcm1-1.fna.fbcdn.net/v/t1.6435-9/117749293_204726837662913_9180934998727467423_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=0b6b33&_nc_ohc=xauKoL8SWfoQ7kNvgGsfXoW&_nc_ht=scontent.ffcm1-1.fna&oh=00_AYDSbdE7FpuDuSQwmErqfrQN_Kb5gMBJj3URS9fJEZg2gg&oe=671B0833'},
    { name: 'Cuitla Vega', image: 'https://yt3.googleusercontent.com/XNkDfKwXXi0LL06t_ljwDP5bTme4X4dIdccuAnAwP4IcLHUNgx7AHM_jlWyMXexTV1mMAcIFlUQ=s900-c-k-c0x00ffffff-no-rj'},

];

const ClientsSection = () => {
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);

        // Intersection Observer for staggered reveal animations
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('staggered-reveal');
                }
            });
        });

        // Observe each artist card
        const artistCards = document.querySelectorAll('.client-card');
        artistCards.forEach((card, index) => {
            card.style.transitionDelay = `${index * 0.2}s`; // Stagger the reveal based on the index
            observer.observe(card);
        });

        // Cleanup on unmount
        return () => {
            artistCards.forEach(card => observer.unobserve(card));
        };
    }, []);

    return (
        <div className="clients-section-wrapper">
            <h1 className="background-title">ARTISTS THAT</h1>
            <h2 className="foreground-title">Trust Us</h2>
            <div className="clients-grid">
                {artists.map((artist) => (
                    <ClientCard key={artist.name} name={artist.name} image={artist.image} />
                ))}
            </div>
        </div>
    );
};

export default ClientsSection;