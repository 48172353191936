import React from 'react';
import AboutUs from '../Main/AboutUs';
import Footer from '../Main/Footer';

const AboutPage = () => {
  return (
    <div>
      <AboutUs/>
      <Footer />
    </div>
  );
};
  
export default AboutPage ;